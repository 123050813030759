// Sidebar.js
import React from 'react';
import "../css/Sidebar.css";
import { Link } from 'react-router-dom';
import { FaUser } from 'react-icons/fa'; 
import { FaUserShield } from 'react-icons/fa';
import { MdSecurity } from 'react-icons/md';
import { getAuthUser } from "../helper/helper";


function Sidebar() {
	const authuser = getAuthUser();	
  return (
    <div className="col_20 pading_to he__con col">
      <div className="menu_slide_bar">
        <Link to="/" className="__logo">
          <img src="https://public.dev.evzone.app/logo/evzone.svg" alt="Logo" />
        </Link>
      </div>
      <div className="lu_namenubar">
        <i className="fa-solid fa-bell icon"></i>
        <ul className="scrollbar">
        
					<li><Link className="active" to={`/u/${authuser}/dashboard`}><FaUser /> Dashboard</Link></li>
          <li><Link to={`/u/${authuser}/users`}><MdSecurity />Users</Link></li>
          <li><Link to={`/u/${authuser}/modules`}><MdSecurity />Modules</Link></li>
          <li><Link to={`/u/${authuser}/roles`}><FaUserShield />Roles</Link></li>
          <li><Link to={`/u/${authuser}/apis`}><FaUserShield />APIs</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;

function isInteger(value) {
	if (typeof value === 'number') {
		return Number.isInteger(value);
	}
	if (typeof value === 'string') {
		return /^-?\d+$/.test(value);
	}
	return false;
}

export const getAuthUser = () => {
	const routePath = window.location.pathname;
	const regex = /\/u\/([^/]+)/;

	const match = routePath.match(regex);
	if (match) {
		const value = match[1].toString();
		if(isInteger(value)) {
			return value;
		}
	}
	return 0;
}

export const getPage = () => {
    const routePath = window.location.search;
    const regex = /[?&]page=(\d+)/;
    const match = routePath.match(regex);
    const pageNumber = match && match[1] ? Number(match[1]) : 1;
		return isInteger(pageNumber) && pageNumber > 0 ? pageNumber : 1;
}

export const getLimit = () => {
    const routePath = window.location.search;
    const regex = /[?&]limit=(\d+)/;
    const match = routePath.match(regex);
    const limitNumber = match && match[1] ? Number(match[1]) : 0;
		return isInteger(limitNumber) && limitNumber > 0 ? limitNumber : (process.env.REACT_APP_MAX_PAGINATION_LIMIT || 10);
}

export const getLimitOptions = () => {
	const limits = [5, 10, 20, 50, 100];
	return limits.map(limit => ({ value: limit, label: limit.toString() }));
}

export const getQueryParameter = (paramName) => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	return urlParams.get(paramName);
}

import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const AdminDashboard = () => {
    return (
    <div className="wrppaer">
        <div className="admin_panel_section">
            <div className="c_full">
                <div className="row">
                    <Sidebar />
                    <div className="col_80 he__con col">
                    <Header />
											<div>
												<Outlet />
											</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default AdminDashboard;

function domain()
{
    return process.env.REACT_APP_API_URL;
}

export function publicDomain()
{
    return process.env.REACT_APP_PUBLIC_URL;
}

export function adminUrl(version)
{
    return domain()+`/api/v${version}/web/core/admin`;
}

export function getRoles()
{
    return adminUrl("1.0")+"/roles";
}

export function getModules()
{
    return adminUrl("1.0")+"/modules";
}

export function getUsers()
{
    return adminUrl("1.0")+"/users";
}

export function getUserRoles()
{
    return adminUrl("1.0")+"/user/role/find";
}

export function getAPIs()
{
    return adminUrl("1.0")+"/apis";
}



export const handleApiError = (error, setIsLoading = null, setError422 = null, handle422AsToast = true, showErrorToast) => {
	if (typeof showErrorToast !== 'function') {
		console.error('showErrorToast must be a function');
		return;
	}

	if (error.response) {
		const { status, data } = error.response;

		switch (status) {
			case 401:
				const errorCode = data.error_code;
				if (errorCode === 'token_expired' || errorCode === 'already_logged_out') {
					//Error401(); // Handle unauthorized errors
				}else if (errorCode === 'session_error'){
					window.location.href = 'https://accounts.dev.evzone.app/?redirecturl=https://admin.dev.evzone.app/'
				} 
				else {
					showErrorToast(data.mobile_error || 'Unauthorized access.', '401Error');
				}
				break;

			case 422:
				if (handle422AsToast) {
					showErrorToast(data.mobile_error || 'Validation error.', '422Error');
				} else if (typeof setError422 === 'function') {
					setError422(data.mobile_error || 'Validation error.');
				}
				break;

			case 500:
				showErrorToast(data.mobile_error || 'Internal server error.', '500Error');
				break;

			default:
				showErrorToast(data.mobile_error || 'An unexpected error occurred.','UnexpectedError');
				break;
		}
	} else {
		showErrorToast('Network error or server is unreachable.', 'NetworkError');
	}

	if (typeof setIsLoading === 'function') {
		setIsLoading(false);
	}
};



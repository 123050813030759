import axios from 'axios';
import { getAuthUser } from "../helper/helper";

const axiosInstance = axios.create({
    headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
    },
    withCredentials: true
});

axiosInstance.interceptors.request.use(
		config => {
			config.headers['X-Authuser'] = getAuthUser();
			return config;
		},
    error => Promise.reject(error)
);

export default axiosInstance;

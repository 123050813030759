import React from 'react';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import { getLimit, getLimitOptions } from '../helper/helper';
import '../css/Pagination.css';

const Pagination = ({
  currentPage,
  totalPages,
  handlePaginationChange,
  visiblePages = 5,
  prevLabel = 'Previous',
  nextLabel = 'Next',
  ellipsis = '...'
}) => { 
  console.log(totalPages);

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: 'orange', // Customize the border color
    borderColor: 'lightgray', // Customize the border color
    boxShadow: 'none', // Remove default shadow
    '&:hover': {
      borderColor: 'blue', // Change border color on hover
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white', // Text color
  }),
};

  return (
    <div className="limit">
   <Select
        options={getLimitOptions()}
	styles={customStyles} 
        value={getLimitOptions().find(option => option.value === getLimit())}
        onChange={(selectedOption) => handlePaginationChange(currentPage, selectedOption.value)}
      />
    <div className="pagination">
     <ReactPaginate
        className="pagination-container"          // Class for the pagination container
        pageClassName="pagination-page"           // Class for each page button
        previousClassName="pagination-btn"         // Class for the previous button
        nextClassName="pagination-btn"             // Class for the next button
        activeClassName="pagination-active"        // Class for the active page
        breakLabel={ellipsis}
        nextLabel={nextLabel}
        onPageChange={(data) => handlePaginationChange(data.selected, getLimit())}
        pageRangeDisplayed={visiblePages}
        pageCount={totalPages}
        previousLabel={prevLabel}
        renderOnZeroPageCount={null}
      />
    </div> 
    </div>
  );
};

export default Pagination;


import React, { useEffect, useState }  from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import "../css/Body.css";
import { FaPenToSquare, FaTrashCan } from 'react-icons/fa6';
import { handleApiError } from '../api/cloudfunction';
import axios from '../instance/axiosInstance';
import WithToast from '../context/withtoast';
import { getAuthUser, getPage, getLimit ,pagination } from '../helper/helper';
import { getUserRoles } from '../instance/url';

const endPoint = getUserRoles();

const UserRoles = ({ showErrorToast }) => {
  const authuser = getAuthUser();
	const navigate = useNavigate();
	const page = getPage(); 
	const limit = getLimit(); 
	const {userid} = useParams();
  const [userRoles, setUserRoles] = useState([]);
  const [currentPage, setCurrentPage] = useState(page);
  const [currentLimit, setCurrentLimit] = useState(limit);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchUserRoles = async (page, limit) => {
      try {
        const response = await axios.get(`${endPoint}?user_id=${userid}&page=${page}&limit=${limit}`);
        if (response.status === 200) {
          console.log('users', response.data.data.values);
          setUserRoles(response.data.data.values);
          setTotalPages(response.data.data.total_pages);
        }
      } catch (error) {
        console.log(error);
        handleApiError(error, null, null, true, showErrorToast);
      }
    };
    fetchUserRoles(currentPage, currentLimit);
  }, [currentPage, currentLimit, showErrorToast]);

  const handlePageChange = (newPage, newLimit) => {
    if (newPage > 0 && newPage <= totalPages) {
			setCurrentLimit(newLimit);
      setCurrentPage(newPage);
			navigate(`?page=${newPage}&limit=${newLimit}`);
    }
  };

  return (
             <div className="body_section">
                <div className="card">
                  <div className="card-body">
                    <h4 className="heading_">User & Role Details</h4>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col" align="left">Name</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userRoles.map((userRole, index) => (
                          <tr key={index}>
                            <td>{(currentPage - 1) * userRoles.length + index + 1}</td>
                            <td align="left">{userRole.name}</td>
                            <td>
														<Link to={`/u/${authuser}/user/role/${userRole.id}`} className='delete_icon'><FaTrashCan /></Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="pagination">
                      <button
                        onClick={() => handlePageChange(currentPage - 1, getLimit())}
                        className="pagination-btn"
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      {pagination(currentPage, totalPages, handlePageChange)}
                      <button
                        onClick={() => handlePageChange(currentPage + 1, getLimit())}
                        className="pagination-btn"
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
						);
};

export default WithToast(UserRoles);

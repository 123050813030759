import React, { useEffect, useState }  from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Pagination from './Pagination';
import { FaPenToSquare, FaTrashCan } from 'react-icons/fa6';
import { handleApiError } from '../api/cloudfunction';
import axios from '../instance/axiosInstance';
import WithToast from '../context/withtoast';
import { getAuthUser, getPage, getLimit , pagination } from '../helper/helper';
import { getAPIs } from '../instance/url';

const endPoint = getAPIs();

const APIs = ({ showErrorToast }) => {
  const authuser = getAuthUser();
	const navigate = useNavigate();
	const page = getPage(); 
	const limit = getLimit(); 
	const {userid} = useParams();
  const [apis, setApis] = useState([]);
  const [currentPage, setCurrentPage] = useState(page);
  const [currentLimit, setCurrentLimit] = useState(limit);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchAPIs = async (page, limit) => {
      try {
        const response = await axios.get(`${endPoint}?page=${page}&limit=${limit}`);
        if (response.status === 200) {
          console.log('apis', response.data.data.values);
          setApis(response.data.data.values);
          setTotalPages(response.data.data.total_pages);
        }
      } catch (error) {
        console.log(error);
        handleApiError(error, null, null, true, showErrorToast);
      }
    };
    fetchAPIs(currentPage, currentLimit);
  }, [currentPage, currentLimit, showErrorToast]);

  const handlePaginationChange = (newPage, newLimit) => {
    if (newPage > 0 && newPage <= totalPages) {
			setCurrentLimit(newLimit);
      setCurrentPage(newPage);
			navigate(`?page=${newPage}&limit=${newLimit}`);
    }
  };

  return (
             <div className="body_section">
                <div className="card">
                  <div className="card-body">
                    <h4 className="heading_">APIs</h4>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col" align="left">Name</th>
                          <th scope="col" align="left">URI</th>
                          <th scope="col" align="left">Module ID</th>
                          <th scope="col" align="left">Module Code</th>
                          <th scope="col" align="left">Role</th>
                          <th scope="col" align="left">Auth</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {apis.map((api, index) => (
                          <tr key={index}>
                            <td>{(currentPage - 1) * apis.length + index + 1}</td>
                            <td align="left">{api.name}</td>
                            <td align="left">{api.uri}</td>
                            <td align="left">{api.module_id}</td>
                            <td align="left">{api.module_code}</td>
                            <td align="left">{api.role}</td>
                            <td align="left">{api.is_auth}</td>
                            <td>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
									<Pagination
									        currentPage={currentPage}
									        totalPages={totalPages}
									        handlePaginationChange={handlePaginationChange}
									        visiblePages={5}
									        prevLabel="Prev"
									        nextLabel="Next"
									        ellipsis="..."
									      />
                  </div>
                </div>
              </div>
						);
};

export default WithToast(APIs);

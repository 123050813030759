import React from "react";
import "./App.css";
import "./css/Responsive.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AdminDashboard from "./pages/AdminDashboard";
import Dashboard from "./components/Dashboard";
import Role from "./components/Role";
import UserRoles from "./components/UserRoles";
import APIs from "./components/APIs";
import Modules from "./components/Modules";
import Users from "./components/Users";
import { getAuthUser } from "./helper/helper";

function App() {
	const authuser = getAuthUser();	
  return (
    <>
      <BrowserRouter>
        <Routes>
				<Route path="/" element={<AdminDashboard />}>
					<Route exact path="/" element={<Navigate to={`/u/${authuser}/dashboard`} replace />} />
          <Route  path={`/u/${authuser}/dashboard`} element={<Dashboard />} />
          <Route  path={`/u/${authuser}/user/role/:userid`} element={<UserRoles />} />
          <Route  path={`/u/${authuser}/users`} element={<Users />} />
          <Route  path={`/u/${authuser}/roles`} element={<Role/>} />
          <Route path={`/u/${authuser}/modules`} element={<Modules/>} />
          <Route path={`/u/${authuser}/apis`} element={<APIs/>} />
				</Route>
					<Route path="*" element={<Navigate to={`/u/${authuser}/dashboard`} replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;

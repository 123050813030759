// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  display: flex;
  align-items: center; /* Center align items */
}

.pagination-container {
  list-style: none; /* Remove default list styling */
  display: flex; /* Use flexbox for layout */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

.pagination-page {
  margin: 0 5px; /* Space between page buttons */
	padding: 6px;
}

.pagination-btn {
  padding: 8px 12px !important; /* Padding for buttons */
  background-color: orange; /* Button background color */
  color: white; /* Button text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Round button corners */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.pagination-page a {
  padding: 8px 12px !important; /* Padding for buttons */
	color: white;
}

.pagination-btn:hover {
  background-color: orange; /* Darker background on hover */
}

.pagination-active {
  background-color: orange; /* Active page button background */
  font-weight: bold; /* Bold text for active page */
  border-radius: 4px; /* Round button corners */
	color: white;
}

.limit {
  display: flex;
  justify-content: space-between;
}

.pagination .break {
	color: orange;
}
`, "",{"version":3,"sources":["webpack://./src/css/Pagination.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB,EAAE,uBAAuB;AAC9C;;AAEA;EACE,gBAAgB,EAAE,gCAAgC;EAClD,aAAa,EAAE,2BAA2B;EAC1C,UAAU,EAAE,mBAAmB;EAC/B,SAAS,EAAE,kBAAkB;AAC/B;;AAEA;EACE,aAAa,EAAE,+BAA+B;CAC/C,YAAY;AACb;;AAEA;EACE,4BAA4B,EAAE,wBAAwB;EACtD,wBAAwB,EAAE,4BAA4B;EACtD,YAAY,EAAE,sBAAsB;EACpC,YAAY,EAAE,kBAAkB;EAChC,kBAAkB,EAAE,yBAAyB;EAC7C,eAAe,EAAE,sCAAsC;AACzD;;AAEA;EACE,4BAA4B,EAAE,wBAAwB;CACvD,YAAY;AACb;;AAEA;EACE,wBAAwB,EAAE,+BAA+B;AAC3D;;AAEA;EACE,wBAAwB,EAAE,kCAAkC;EAC5D,iBAAiB,EAAE,8BAA8B;EACjD,kBAAkB,EAAE,yBAAyB;CAC9C,YAAY;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;CACC,aAAa;AACd","sourcesContent":[".pagination {\n  display: flex;\n  align-items: center; /* Center align items */\n}\n\n.pagination-container {\n  list-style: none; /* Remove default list styling */\n  display: flex; /* Use flexbox for layout */\n  padding: 0; /* Remove padding */\n  margin: 0; /* Remove margin */\n}\n\n.pagination-page {\n  margin: 0 5px; /* Space between page buttons */\n\tpadding: 6px;\n}\n\n.pagination-btn {\n  padding: 8px 12px !important; /* Padding for buttons */\n  background-color: orange; /* Button background color */\n  color: white; /* Button text color */\n  border: none; /* Remove border */\n  border-radius: 4px; /* Round button corners */\n  cursor: pointer; /* Change cursor to pointer on hover */\n}\n\n.pagination-page a {\n  padding: 8px 12px !important; /* Padding for buttons */\n\tcolor: white;\n}\n\n.pagination-btn:hover {\n  background-color: orange; /* Darker background on hover */\n}\n\n.pagination-active {\n  background-color: orange; /* Active page button background */\n  font-weight: bold; /* Bold text for active page */\n  border-radius: 4px; /* Round button corners */\n\tcolor: white;\n}\n\n.limit {\n  display: flex;\n  justify-content: space-between;\n}\n\n.pagination .break {\n\tcolor: orange;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
